export const userStates = [
  { label: 'NSW', value: 'nsw' },
  { label: 'VIC', value: 'vic' },
  { label: 'QLD', value: 'qld' },
  { label: 'WA', value: 'wa' },
  { label: 'SA', value: 'sa' },
  { label: 'TAS', value: 'tas' },
  { label: 'NT', value: 'nt' },
  { label: 'ACT', value: 'act' },
  { label: 'International', value: 'international' }
]

export const printableStates = [
  { label: 'NSW/ACT', value: 'nsw' },
  { label: 'VIC/WA/NT', value: 'vic' },
  { label: 'QLD', value: 'qld' },
  { label: 'SA', value: 'sa' },
  { label: 'TAS', value: 'tas' },
  { label: 'Print', value: 'print' }
]
